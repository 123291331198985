<template>
  <v-navigation-drawer absolute temporary width="360" v-model="mutatedValue">
    <v-toolbar dense flat class="mt-2 mb-0">
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Nueva Transición</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card flat class="mx-auto scroll">
      <v-form v-model="isValid" ref="form" @submit="submit" class="new__issue-status__form ma-5">
        <v-row justify="center" class="mt-8">
          <base-error :error="error"></base-error>
        </v-row>
        <v-row no-gutters dense class="mb-2 flex-column">
          <v-col>
            <v-text-field outlined dense label="Nombre" v-model="name"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="mb-2">
          <v-col>
            <v-select
              outlined
              dense
              v-model="fromStatusId"
              item-value="id"
              item-text="name"
              :items="filteredFromStatus"
              label="De status"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="mb-2">
          <v-col>
            <v-select
              outlined
              dense
              v-model="toStatusId"
              item-value="id"
              item-text="name"
              :items="filteredToStatus"
              label="A status"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="mb-8">
          <v-col>
            <v-select
              outlined
              dense
              hide-details
              v-model="groupId"
              item-value="id"
              item-text="name"
              :items="getAllGroups"
              label="Grupo"
            ></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="mb-2">
          <v-col>
            <v-select
              outlined
              dense
              hide-details
              v-model="formId"
              item-value="id"
              item-text="name"
              :items="getFormsForms"
              label="Formulario"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn rounded outlined block color="primary" class="mt-5" @click="close">Cancelar</v-btn>
          </v-col>
          <v-col>
            <v-btn
              rounded
              :loading="isLoading"
              type="submit"
              block
              color="primary"
              class="mt-5"
              :disabled="!isValid"
            >Agregar</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { to } from '@/helpers'
import { errorMessage } from '@/mixins'
import { mapGetters } from 'vuex'
import { WORKFLOWS_CREATE_TRANSITION } from '@/store/actions.type'
export default {
  name: 'SWorkflowTransitionSidebarNew',
  props: {
    value: {
      required: true
    },
    workflowId: {
      required: true
    }
  },
  data() {
    return {
      error: null,
      name: null,
      fromStatusId: null,
      toStatusId: null,
      groupId: null,
      formId: null,
      isValid: false,
      isLoading: false,
      mutatedValue: false
    }
  },
  computed: {
    ...mapGetters([
      'getAllGroups',
      'getIssueStatusIssueStatuses',
      'getFormsForms',
      'getWorkflowsFormattedTransitions',
      'getWorkflowTransitions'
    ]),
    filteredFromStatus() {
      return this.getIssueStatusIssueStatuses
    },
    filteredToStatus() {
      // To status can only be any other than open
      return this.getIssueStatusIssueStatuses.filter(
        elem => elem.statusType && elem.statusType.slug !== 'open'
      )
    }
  },
  methods: {
    close() {
      this.mutatedValue = false
    },
    async submit(evt) {
      evt.preventDefault()
      const transition = {
        name: this.name,
        to: {
          id: this.toStatusId
        },
        from: {
          id: this.fromStatusId
        },
        group: {
          id: this.groupId
        },
        form: {
          id: this.formId
        }
      }
      this.error = null
      const [err] = await to(
        this.$store.dispatch(WORKFLOWS_CREATE_TRANSITION, {
          workflowId: this.workflowId,
          transition
        })
      )
      if (err) {
        this.error = err
      } else {
        this.reset()
        this.close()
      }
    },
    reset() {
      this.$refs.form.reset()
    }
  },
  components: {},
  mixins: [errorMessage],
  watch: {
    value: function(value) {
      this.mutatedValue = value
    },
    mutatedValue: function(value) {
      if (!value) {
        this.$emit('input', false)
      }
    }
  }
}
</script>
