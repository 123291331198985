<template>
  <div class="workflows" @drop.prevent @dragover.prevent>
    <v-toolbar flat>
      <v-toolbar-title>Editar workflow</v-toolbar-title>
    </v-toolbar>
    <s-workflow-transition-sidebar-new v-model="newTransition" :workflowId="workflowId"></s-workflow-transition-sidebar-new>
    <s-workflow-transition-sidebar-edit
      v-model="editTransition"
      :workflowId="workflowId"
      :transitionId="transitionId"
    ></s-workflow-transition-sidebar-edit>
    <v-container fluid>
      <v-row justify="center" class="mx-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-form v-model="isValid" ref="form" class="edit__workflows__form ma-5">
        <v-row no-gutters class="mb-2">
          <v-col>
            <v-text-field
              outlined
              :rules="rules.issueStatusName"
              label="*Nombre"
              class="ma-2"
              v-model="name"
              :error-messages="nameError"
              :loading="nameLoading"
              @keydown="onNameChange"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              name="description"
              label="Descripción"
              :loading="descriptionLoading"
              v-model="description"
              @keydown="onDescriptionChange"
              class="ma-2"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              outlined
              v-model="issueTypeId"
              item-value="id"
              item-text="name"
              class="ma-2"
              :items="getIssueTypesIssueTypes"
              :loading="issueTypeIdLoading"
              label="*Tipo de incidencia"
              @change="onIssueTypeIdChange"
            ></v-select>
          </v-col>
        </v-row >
        <v-row sm="2">
          <v-col md="6">
            <v-row @drop.prevent="addFile" @dragover.prevent>
              <v-avatar size="88" color="grey">
                <v-img v-if="iconUrl" :src="iconUrl"></v-img>
                <v-icon v-else dark>mdi-image</v-icon>
              </v-avatar>
              <v-file-input
                outlined
                show-size
                v-model="icon"
                accept="image/png, image /jpeg, image/bmp"
                label="*Icono"
                placeholder="Elige un ícono"
                :loading="isIconLoading"
                @change="onIconChange">
              </v-file-input>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container fluid class="mt-2">
      <v-row justify="end">
        <v-btn
          class="mb-2 mr-8"
          rounded
          color="primary"
          @click="onNewTransitionClick"
        >Nueva transición</v-btn>
      </v-row>
      <v-row class="grey lighten-5 ma-4" style="height:300px">
        <v-col>
          <workflow-chart
            v-if="!isWorkflowLoading"
            :transitions="getWorkflowsFormattedTransitions.transitions"
            :states="getWorkflowsFormattedTransitions.states"
            @transition-click="onEditTransitionClick($event)"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      Flujo actualizado
      <v-btn color="success" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import SWorkflowTransitionSidebarNew from '@/components/app/workflows/s-workflow-transition-sidebar-new'
import SWorkflowTransitionSidebarEdit from '@/components/app/workflows/s-workflow-transition-sidebar-edit'
import WorkflowChart from 'vue-workflow-chart'
import { to, debounce } from '@/helpers'
import rules from '@/constants/input-validation'
import {
  WORKFLOWS_GET_WORKFLOW,
  WORKFLOWS_UPDATE_WORKFLOW,
  ISSUE_TYPES_GET_ISSUE_TYPES,
  WORKFLOWS_GET_TRANSITIONS,
  ISSUE_STATUS_GET_ISSUE_STATUSES,
  GROUPS_GET_GROUPS,
  FORMS_GET_FORMS,
  FILES_UPLOAD_FILE
} from '@/store/actions.type'
import { errorMessage } from '@/mixins'
import { mapGetters } from 'vuex'
export default {
  name: 'IssueStatusEdit',
  props: {
    workflowId: {
      required: true
    }
  },
  created() {
    this.getWorkflow()
    this.getIssueTypesTypes()
    this.getWorkflowTransitions()
    this.getIssueStatus()
    this.getGroups()
    this.getForms()
  },
  data() {
    return {
      snackbar: false,
      rules,
      isValid: false,
      isLoading: false,
      transitionId: null,
      newTransition: false,
      editTransition: false,
      description: '',
      descriptionLoading: false,
      descriptionError: '',
      name: '',
      nameLoading: false,
      nameError: '',
      issueTypeId: null,
      issueTypeIdLoading: false,
      issueTypeIdError: '',
      isWorkflowLoading: true,
      iconUrl: null,
      icon: null,
      isIconLoading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters([
      'getWorkflowsWorkflow',
      'getFilesFile',
      'getIssueTypesIssueTypes',
      'getWorkflowsFormattedTransitions'
    ])
  },
  components: {
    WorkflowChart,
    SWorkflowTransitionSidebarNew,
    SWorkflowTransitionSidebarEdit
  },
  mixins: [errorMessage],
  methods: {
    back() {
      this.$router.push('/workflows')
    },
    onNewTransitionClick() {
      this.newTransition = true
      this.editTransition = false
    },
    onEditTransitionClick(id) {
      this.transitionId = id
      this.editTransition = true
      this.newTransition = false
    },
    onNameChange: debounce(async function() {
      this.nameLoading = true
      await this.update({ id: this.workflowId, name: this.name })
      this.nameLoading = false
    }, 500),
    onDescriptionChange: debounce(async function() {
      this.descriptionLoading = true
      await this.update({
        id: this.workflowId,
        description: this.description
      })
      this.descriptionLoading = false
    }, 500),
    onIssueTypeIdChange: debounce(async function() {
      this.issueTypeIdLoading = true
      await this.update({
        id: this.workflowId,
        issueType: {
          id: this.issueTypeId
        }
      })
      this.issueTypeIdLoading = false
    }, 500),
    async update(workflow) {
      this.error = null
      const [err] = await to(
        this.$store.dispatch(WORKFLOWS_UPDATE_WORKFLOW, workflow)
      )
      if (err) {
        this.error = err
      } else {
        this.snackbar = true
      }
    },
    async getWorkflowTransitions() {
      this.isWorkflowLoading = true
      const [err] = await to(
        this.$store.dispatch(WORKFLOWS_GET_TRANSITIONS, this.workflowId)
      )
      if (err) {
        this.error = err
        this.isWorkflowLoading = false
      } else {
        this.isWorkflowLoading = false
      }
    },
    async getWorkflow() {
      this.isLoading = true
      const [err] = await to(
        this.$store.dispatch(WORKFLOWS_GET_WORKFLOW, this.workflowId)
      )
      if (err) {
        // @TODO: if 404
        this.error = err

        this.isLoading = false
      } else {
        this.name = this.getWorkflowsWorkflow.name
        this.description = this.getWorkflowsWorkflow.description
        this.issueTypeId = this.getWorkflowsWorkflow.issueType.id
        this.isLoading = false
        this.iconUrl = this.getWorkflowsWorkflow.iconUrl
      }
    },
    async getIssueTypesTypes() {
      this.isLoading = true
      const [err] = await to(this.$store.dispatch(ISSUE_TYPES_GET_ISSUE_TYPES))
      if (err) {
        this.error = err
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    async getIssueStatus() {
      const [err] = await to(
        this.$store.dispatch(ISSUE_STATUS_GET_ISSUE_STATUSES)
      )
      if (err) {
        this.error = err
      }
    },
    async getGroups() {
      const [err] = await to(this.$store.dispatch(GROUPS_GET_GROUPS))
      if (err) {
        this.error = err
      }
    },
    async getForms() {
      const [err] = await to(this.$store.dispatch(FORMS_GET_FORMS))
      if (err) {
        this.error = err
      }
    },
    async createAndUpload() {
      this.isIconLoading = true
      this.error = null
      const file = {
        file: this.icon,
        type: 'icons'
      }
      const [err] = await to(this.$store.dispatch(FILES_UPLOAD_FILE, file))
      if (err) {
        this.error = err
        this.isIconLoading = false
        if (err.status === 422) {
          this.fileError = this.errorMessage(err, 'type')
        }
      } else {
        this.wasIconChanged = true
        this.isIconLoading = false
        this.update({
          id: this.workflowId,
          iconId: this.getFilesFile.id
        })
      }
    },
    onIconChange(file) {
      if (!file) {
        this.iconUrl = null
      }
    },
    addFile(e) {
      const droppedFile = e.dataTransfer.files[0]
      const admittedFileTypes = ['image/png', 'image/jpeg', 'image/bmp']
      if (!droppedFile) return
      if (admittedFileTypes.includes(droppedFile.type)) {
        this.icon = droppedFile
      }
    }
  },
  watch: {
    newTransition: function(value) {
      if (!value) {
        this.getWorkflowTransitions()
      }
    },
    editTransition: function(value) {
      if (!value) {
        this.getWorkflowTransitions()
      }
    },
    icon: function() {
      if (this.icon) {
        this.createAndUpload()
      }
    },
    getFilesFile: function() {
      if (this.getFilesFile.url) {
        this.iconUrl = this.getFilesFile.url
      } else {
        this.isNewIcon = false
      }
    }
  }
}
</script>
